import { FilterList, FilterListItem, useGetList } from 'react-admin';
import AppIcon from '@mui/icons-material/AppsOutlined';

export const ProductFilter = () => {
    const { data } = useGetList(
        'customer',
        {
            filter: [],
            pagination: {page: 1, perPage: 10000},
            sort: {
                field: 'id',
                order: 'DESC'
            }
        }
    );

    if (!data) {
        return null;
    }

    const products = new Set();
    return (
        <FilterList
            label="Product"
            icon={<AppIcon />}
        >
            {
                data.filter(
                    (entry: {product: string}) => {
                        if (products.has(entry.product)) {
                            return false;
                        }

                        products.add(entry.product);
                        return true;
                    }
                ).map(
                    (entry: {id: string, product: string}) => (
                        <FilterListItem
                            label={entry.product}
                            key={entry.id}
                            value={{ product: entry.product }}
                        />
                    )
                )
            }
        </FilterList>
    );
};