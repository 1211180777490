import { FilterList, FilterListItem, useGetList } from 'react-admin';
import PoolIcon from '@mui/icons-material/GroupWorkOutlined';

export const PoolFilter = () => {
    const { data } = useGetList(
        'pool',
        {
            filter: [],
            pagination: {page: 1, perPage: 500},
            sort: {
                field: 'id',
                order: 'DESC'
            }
        }
    );

    if (!data) {
        return null;
    }

    return (
        <FilterList
            label="Pool"
            icon={<PoolIcon />}
        >
            {
                data.map(
                    (entry: {id: string, comment: string}) => (
                        <FilterListItem
                            label={entry.id}
                            key={entry.id}
                            value={{ pool: entry.id }}
                        />
                    )
                )
            }
        </FilterList>
    );
};