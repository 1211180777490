import { DataProvider, GetListParams, GetListResult, GetOneParams, GetOneResult, HttpError, RaRecord } from 'react-admin';
import { stringify } from 'query-string';

import { client } from './AuthProvider';

export const apiUrl = 'https://eaiws-server-fallback.pcon-solutions.com/manage/v1';
export const GatekeeperManagedApiProvider = new class {
    public async getOne<RecordType extends RaRecord>(resource: string, params: GetOneParams): Promise<GetOneResult<RecordType>> {
        // FixMe: extend the backend api
        const all = await this.getList<RecordType>(
            resource,
            {
                pagination: {
                    perPage: 1000,
                    page: 1
                },
                sort: {
                    field: 'id',
                    order: 'DESC'
                },
                filter: []
            }
        );
        const result = all.data.filter((entry) => entry.id === params.id)[0];
        return {
            data: result
        };
    }

    public async getList<RecordType extends RaRecord>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
        if (Object.keys(params.filter).length > 0) {
            // Note: right now, the backend does not support filtering
            // therefore as a workaround the filtering is done inside this class
            return this.getFilteredList(resource, params);
        }

        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        const response = await client.fetchWithAuth(
            url,
            {
                headers: {
                    Accept: 'application/json',
                }
            }
        );

        // Note: the pCon.login `fetchWithAuth` returns null if no access token
        // was found
        if (response == null) {
            throw new HttpError('Unauthorized', 401);
        }

        if (response!.status < 200 && response!.status > 299) {
            throw new HttpError('Invalid status', response?.status);
        }

        return {
            data: await response?.json(),
            total: parseInt(response?.headers.get('X-Total-Count')!, 10),
        };
    }

    private async getFilteredList<RecordType extends RaRecord>(resource: string, params: GetListParams): Promise<GetListResult<RecordType>> {
        const results = await this.getList<RecordType>(resource, {
            sort: params.sort,
            pagination: {
                page: 1,
                perPage: 10000
            },
            filter: {} // must be empty!
        });
        const filteredDate = results.data.filter(
            (entry): boolean => {
                for (const [key, value] of Object.entries(params.filter)) {
                    if (entry[key] !== value) {
                        return false;
                    }
                }

                return true;
            }
        );

        return {
            data: filteredDate.slice(
                (params.pagination.page - 1) * params.pagination.perPage,
                (params.pagination.page * params.pagination.perPage) - 1
            ),
            total: filteredDate.length
        };
    }
}() as unknown as DataProvider; // Note: some methods are missing -> therefore we have to cast it or implement the missing methods
