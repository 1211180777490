import { List, Datagrid, TextField, FunctionField, Pagination, BooleanField } from 'react-admin';
import { Link } from 'react-router-dom';
import { CustomerFilterSidebar } from './CustomerFilterSidebar';

export const CustomerList = () => (
    <List
        aside={<CustomerFilterSidebar />}
        bulkActionButtons={false}
        perPage={1000}
        pagination={
            <Pagination rowsPerPageOptions={[50, 100, 250, 500, 1000]} />
        }
    >
        <Datagrid rowClick="show">
            <FunctionField
                label="Id"
                sortable={false}
                render={(record: any) => {
                    return (
                        <a
                            href={`https://cd.easterngraphics.com/cloud/link-generator/master/#GATEKEEPER_ID=${record.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {record.id}
                        </a>
                    );

                }}
            />;
            <TextField source="product" sortable={false} />
            <TextField source="type" sortable={false} />
            <FunctionField
                label="Pool"
                sortable={false}
                render={(record: any) => {
                    return (
                        <Link
                            to={`/pool/${record.pool}/show`}
                        >
                            {record.pool}
                        </Link>
                    );

                }}
            />;
            <TextField source="comment" sortable={false} />
            <TextField source="contact_person" sortable={false} />
            <TextField source="data_source" sortable={false} emptyText="dynamic" />
            <BooleanField source="refresh_token" looseValue={true} />
            <TextField source="startup" sortable={false} />
            <FunctionField
                label="Created"
                sortable={false}
                render={(record: any) => new Intl.DateTimeFormat().format(record.crtime * 1000)}
            />
        </Datagrid>
    </List>
);
