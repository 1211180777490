import { TextField, BooleanField, FunctionField, RaRecord, Show, SimpleShowLayout } from 'react-admin';

export const PoolShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" sortable={false} />
                <TextField source="comment" sortable={false} />
                <FunctionField
                    source="servers"
                    sortable={false}
                    render={
                        (record?: RaRecord) => {
                            // reduce duplicate entries
                            const servers: Set<string> = new Set(record?.servers);
                            return (
                                <ul>
                                    {Array.from(servers).map((url: string) => (
                                        <li key={url}>{url}</li>
                                    ))}
                                </ul>
                            );
                        }
                    }
                />
                <BooleanField source="allow_usage_via_tag" sortable={false} />
                <FunctionField
                    label={'JSON'}
                    sortable={false}
                    render={
                        (record?: RaRecord) => {
                            return (
                                <pre>
                                    {JSON.stringify(record, undefined, 4)}
                                </pre>
                            );
                        }
                    }
                />
            </SimpleShowLayout>
        </Show>
    );
};