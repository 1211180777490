import { Admin, CustomRoutes, Layout, Resource } from 'react-admin';
import { Route } from "react-router-dom";
import { CustomerList } from './components/customer/CustomerList';
import { LoginPage } from './components/login/LoginPage';
import { PoolList } from './components/pool/PoolList';
import { PoolShow } from './components/pool/PoolShow';
import { PConLoginAuthProvider } from './provider/AuthProvider';
import { GatekeeperManagedApiProvider } from './provider/DataProvider';
import { RequestErrorRateList } from './components/requestError/RequestErrorRateList';
import { CustomAppBar } from './components/appBar/CustomAppBar';
import { QueryClient } from 'react-query';
import { CatchAll } from './components/errorPage/CatchAll';
import { CustomerShow } from './components/customer/CustomerShow';
import { RequestLookupForm } from './components/status/request/RequestLookupForm';

export const CustomLayout = (props: any) => <Layout {...props} sidebar={() => null} appBar={CustomAppBar} />;
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 30 * 1000, // 5 minutes
        },
    },
});

const App = () => (
    <Admin
        authProvider={PConLoginAuthProvider}
        requireAuth
        dataProvider={GatekeeperManagedApiProvider}
        loginPage={LoginPage}
        disableTelemetry
        queryClient={queryClient}
        layout={CustomLayout}
        catchAll={CatchAll}
    >
        {(permissions) => {
            if (!permissions.accessGranted)  {
                return null;
            }

            return (
                <>
                    <Resource name="pool" list={PoolList} show={PoolShow} />
                    <Resource name="customer" list={CustomerList} show={CustomerShow} />
                    <Resource
                        name="status/customer/errorRates"
                        list={RequestErrorRateList}
                        options={{ label: 'Request error rates' }}
                    />
                    <CustomRoutes>
                        <Route path="/status/request" element={<RequestLookupForm />} />
                    </CustomRoutes>
                </>
            );
        }}
    </Admin>
);

export default App;