import { PConLoginClient } from '@egr/pcon-login-js';
import { apiUrl } from './DataProvider';

export const client = new PConLoginClient({
    client_id: 'EGR-GK',
    // remove query string and co
    redirect_uri: `${window.location.origin}${window.location.pathname}`,
    persist_tokens: true,
    want_refresh_token: true
});

export const PConLoginAuthProvider = new class {
    public async login(): Promise<void> {
        if (client.isAccessTokenValid()) {
            return;
        }

        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.has('code') && searchParams.has('state')) {
            await client.handleRedirect();
            client.redirectToNext();
        } else {
            client.loginWithRedirect(client.options.redirect_uri);
        }
    }

    public async logout(): Promise<void> {
        if (client.isAccessTokenValid()) {
            return client.logout(client.options.redirect_uri);
        }
    }

    public async checkError(): Promise<void> {
    }

    public async checkAuth(): Promise<void> {
        if (!client.isAccessTokenValid() && (await client.getAccessToken() == null)) {
            this.login();
        }
    }

    public async getPermissions(): Promise<{loggedIn: boolean, accessGranted: boolean}> {
        const response = await client.fetchWithAuth(apiUrl + '/permission');
        return {
            loggedIn: client.isAccessTokenValid(),
            accessGranted: response?.status === 200
        };
    }
}();