import Button from '@mui/material/Button';
import ErrorIcon from '@mui/icons-material/Report';
import { Title, useLogout } from 'react-admin';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';

export const AccessDenied = () => {
    const logout = useLogout();
    return (
        <Card>
            <Title title="Error" />
            <CardContent>
                <h1><ErrorIcon /> Access denied</h1>
                <div>This login does not have the required permissions.</div>
                <div>
                    <Button
                        variant="contained"
                        startIcon={<ExitIcon />}
                        onClick={() => logout({})}
                    >
                        Logout
                    </Button>
                </div>
            </CardContent>
        </Card>
    );
};