import { Box, Card, CardContent } from '@mui/material';
import { PoolFilter } from './filter/PoolFilter';
import { ProductFilter } from './filter/ProductFilter';
import { TypeFilter } from './filter/TypeFilter';

export const CustomerFilterSidebar = () => (
    <Box
        sx={{
            display: {
                xs: 'none',
                sm: 'block'
            },
            order: -1, // display on the left rather than on the right of the list
            width: '15em',
                marginRight: '1em',
        }}
    >
        <Card>
            <CardContent>
                <TypeFilter />
                <ProductFilter />
                <PoolFilter />
            </CardContent>
        </Card>
    </Box>
);
