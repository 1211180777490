import { List, Datagrid, TextField, BooleanField, Pagination, FunctionField, RaRecord } from 'react-admin';

export const PoolList = () => (
    <List
        bulkActionButtons={false}
        perPage={100}
        pagination={
            <Pagination rowsPerPageOptions={[100, 500]} />
        }
    >
        <Datagrid rowClick="show">
		    <TextField source="id" sortable={false} />
            <TextField source="comment" sortable={false} />
            <FunctionField
                source="servers"
                sortable={false}
                render={
                    (record?: RaRecord) => {
                        // reduce duplicate entries
                        const servers: Set<string> = new Set(record?.servers);
                        return (
                            <ul>
                                {Array.from(servers).map((url: string) => (
                                    <li key={url}>{url}</li>
                                ))}
                            </ul>
                        );
                    }
                }
            />
            <BooleanField source="allow_usage_via_tag" sortable={false} />
        </Datagrid>
    </List>
);