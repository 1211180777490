import Link from '@mui/material/Link';
import { List, Datagrid, TextField, FunctionField } from 'react-admin';

export const RequestErrorRateList = () => (
    <List
        bulkActionButtons={false}
        pagination={false}
    >
        <Datagrid>
            <FunctionField
                label="Customer id"
                sortable={false}
                render={(record: any) => {
                    return (
                        <Link
                            href={`http://ks01.easterngraphics.com/preview/link-generator/#GATEKEEPER_ID=${record.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {record.id}
                        </Link>
                    );

                }}
            />;
            <FunctionField
                label="Error rate"
                sortable={true}
                render={(record: any) => {
                    return `${(record.errorRate * 100).toFixed(2)}%`;
                }}
            />;
            <TextField label={"Total request count"} source="requestCount" sortable={false} />
            <FunctionField
                label="Last error time"
                sortable={true}
                render={(record: any) => {
                    return new Date(record.lastErrorTime).toISOString();
                }}
            />;
        </Datagrid>
    </List>
);