import { BooleanField, FunctionField, RaRecord, Show, SimpleShowLayout, TextField } from 'react-admin';

export const CustomerShow = () => {
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="type" />
                <FunctionField
                    label="Created"
                    sortable={false}
                    render={(record: any) => new Intl.DateTimeFormat().format(record.crtime * 1000)}
                />
                <TextField source="comment" />
                <TextField source="contact_person" />
                <TextField source="pool" />
                <TextField source="product" />
                <TextField source="data_source" emptyText="dynamic" />
                <BooleanField source="refresh_token" looseValue={true} />
                <TextField source="startup" />
                <FunctionField
                    label={'JSON'}
                    render={
                        (record?: RaRecord) => {
                            return (
                                <pre>
                                    {JSON.stringify(record, undefined, 4)}
                                </pre>
                            );
                        }
                    }
                />
            </SimpleShowLayout>
        </Show>
    );
};
