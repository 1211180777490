import { useEffect } from 'react';
import { useLogin } from 'react-admin';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


export const LoginForm = () => {
    const login = useLogin();
    useEffect(() => {
        const location = window.location.href;
        const url = new URL(window.location.href);
        const { searchParams } = url ;
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        // If code is present, we came back from the provider
        if (code && state) {
            login({ location });
        }
    }, [login]);

    const handleLogin = () => {
        // Do not provide code, just trigger the redirection
        login({});
    };

    return (
        <>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    Please authenticate via pCon.login.
                </Typography>
            </CardContent>
            <CardActions>
                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    onClick={handleLogin}
                >
                    Login
                </Button>
            </CardActions>
        </>
    );
};