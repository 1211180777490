import { NotFound, usePermissions } from 'react-admin';
import { AccessDenied } from './AccessDenies';

export const CatchAll = () => {
    const permissions = usePermissions();
    if (permissions.permissions?.accessGranted === false) {
        return <AccessDenied />;
    }

    return <NotFound />;
};