import { FilterList, FilterListItem, useGetList } from 'react-admin';
import TypeIcon from '@mui/icons-material/CategoryOutlined';

export const TypeFilter = () => {
    const { data } = useGetList(
        'customer',
        {
            filter: [],
            pagination: {page: 1, perPage: 1000},
            sort: {
                field: 'id',
                order: 'ASC'
            }
        }
    );

    if (!data) return null;

    const types = new Set();
    return (
        <FilterList
            label="Type"
            icon={<TypeIcon />}
        >
            {
                data.filter(
                    (entry: {type: string}) => {
                        if (types.has(entry.type)) {
                            return false;
                        }

                        types.add(entry.type);
                        return true;
                    }
                ).map(
                    (entry: {id: string, type: string}) => (
                        <FilterListItem
                            label={entry.type}
                            key={entry.id}
                            value={{ type: entry.type }}
                        />
                    )
                )
            }
        </FilterList>
    );
};