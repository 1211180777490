import * as React from "react";
import { Card, CardContent } from '@mui/material';
import { Title } from 'react-admin';

import {
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { client } from '../../../provider/AuthProvider';
import { apiUrl } from '../../../provider/DataProvider';

export const RequestLookupForm = () => {
    const valueRef = React.useRef<{value: string}>();
    const [jsonData, setJsonData] = React.useState();

    const onSubmit = async () => {
        const value = valueRef.current?.value;
        const response = await client.fetchWithAuth(`${apiUrl}/status/request/${value}`);
        const data = await response?.json();
        setJsonData(data);
    };

    return (
        <Card>
            <Title title="Request lookup" />
            <CardContent>
                <FormControl onSubmit={onSubmit}>
                    <TextField
                        label="Request-ID"
                        size="medium"
                        variant="outlined"
                        onSubmit={onSubmit}
                        onKeyDown={(e) => {
                            if (e.code === 'Enter') {
                                onSubmit();
                            }
                        }}
                        inputRef={valueRef}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
                { jsonData != null ? <pre>{JSON.stringify(jsonData, undefined, 4)}</pre> : null}
            </CardContent>
        </Card>
    );
};