import { AppBar, Toolbar, Box } from '@mui/material';
import { TitlePortal, RefreshIconButton, useCreatePath } from 'react-admin';
import { useNavigate } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation } from 'react-router-dom';

function LinkTab(props: {value: string, label: string}) {
    const navigate = useNavigate();
    const createPath = useCreatePath();

    return (
        <Tab
            component="a"
            color="inherit"
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                event.preventDefault();
                navigate(createPath({ resource: props.value, type: 'list' }));
            }}
            {...props}
        />
    );
}

function getCurrentLocation(location: string) {
    switch (location) {
        case 'pool':
        case 'customer':
        case 'status/customer/errorRates':
            return location;
        default:
            return 'pool';
    }
}

export const CustomAppBar = () => {
    const location = useLocation();
    return (
        <AppBar>
            <Toolbar>
                <TitlePortal />
                <Box flex="1">
                    <Tabs textColor='inherit' value={getCurrentLocation(location.pathname.substring(1))}>
                        <LinkTab label="Pool" value="pool" />
                        <LinkTab label="Customer" value="customer" />
                        <LinkTab label="Error rates" value="status/customer/errorRates" />
                        <LinkTab label="Request lookup" value="status/request" />
                    </Tabs>
                </Box>
                <RefreshIconButton />
            </Toolbar>
        </AppBar>
    );
};